<template>
  <div>
    <div class="container">
      <b-form @submit.prevent="addTreatment" @keydown.enter="handleEnterKey">
        <fieldset :disabled="mode === 'edit'">
          <b-form-group
            label-cols="3"
            label="แพทย์:"
            label-align="right"
            label-class="font-weight-bold"
          >
            <b-form-select
              :class="{
                'is-invalid': $v.actionUid.$error,
              }"
              v-model="$v.actionUid.$model"
              :options="getDoctorList"
              value-field="uid"
              text-field="fullName"
              :size="size"
              :disabled="isDashboardRoute"
            ></b-form-select>
          </b-form-group>
          <hr />
          <TreatmentPlanTable
            :isNhso="isNhso"
            @clickItem="handleTreatmentPlanItemClicked"
          />

          <div class="form-row">
            <div id="toothNumberInput" class="form-group col-md-6">
              <multiselect
                class="col p-0 vmHideDropdown"
                :class="{ 'is-invalid': $v.toothList.$error }"
                v-model="$v.toothList.$model"
                :options="getToothList"
                :multiple="true"
                placeholder="เลือกซี่ฟัน/บริเวณ"
                track-by="id"
                :custom-label="customToothLabel"
                :disabled="mode === 'edit'"
              ></multiselect>
            </div>
            <div id="toothSurfacesInput" class="form-group col-md-6">
              <multiselect
                ref="toothSurfacesInput"
                class="col p-0 vmHideDropdown"
                v-model="toothSurfaceList"
                :options="getToothSurfaceList"
                :searchable="false"
                :multiple="true"
                :group-select="false"
                placeholder="ด้าน"
                track-by="id"
                :custom-label="customToothLabel"
                :close-on-select="false"
                :disabled="!enableSurfaceInput"
              ></multiselect>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <b-form-group
                class="mb-2"
                description="ดูรายการทั้งหมดใน Dx. List"
              >
                <b-input-group>
                  <multiselect
                    class="col p-0 small-text"
                    :class="{
                      'multiselect-md-input': size != 'sm',
                      'is-invalid': $v.treatDiagList.$error,
                      'multiselect-black-border': !$v.treatDiagList.$error,
                    }"
                    v-model="$v.treatDiagList.$model"
                    :options="diagnosisList"
                    :multiple="false"
                    group-values="libs"
                    group-label="category"
                    :group-select="false"
                    placeholder="พิมพ์ค้นหาการวินิจฉัย"
                    track-by="nameEn"
                    :custom-label="customDxLabel"
                    selectLabel
                    selectedLabel
                    deselectLabel
                    :disabled="mode === 'edit'"
                  >
                    <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
                    <template #option="props">
                      <span v-if="props.option.$isLabel">
                        {{ props.option.$groupLabel }}
                      </span>
                      <span v-else>
                        <b-button
                          class="mr-1 small"
                          size="xs"
                          variant="outline-info"
                          :pressed="props.option.favorite"
                          @click.stop="favDxToggle(props.option)"
                        >
                          <i class="far fa-star"></i>
                        </b-button>
                        {{ customDxLabel(props.option) }}
                      </span>
                    </template>
                  </multiselect>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      @click="openTreatDiagModal"
                      :size="size"
                      >Dx. list</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <b-form-group class="mb-2">
                <b-input-group>
                  <multiselect
                    class="col p-0 small-text"
                    :class="{
                      'is-invalid': $v.treatList.$error,
                      'multiselect-black-border': !$v.treatList.$error,
                      'multiselect-md-input': size != 'sm',
                    }"
                    v-model="$v.treatList.$model"
                    :options="treatmentList"
                    :multiple="false"
                    group-values="libs"
                    group-label="category"
                    :group-select="false"
                    placeholder="พิมพ์ค้นหาการรักษา"
                    :custom-label="customTxDrugsLabel"
                    track-by="nameTh"
                    selectLabel
                    selectedLabel
                    deselectLabel
                    :disabled="mode === 'edit'"
                    @select="focusPriceInput"
                  >
                    <template #option="props">
                      <span v-if="props.option.$isLabel">
                        {{ props.option.$groupLabel }}
                      </span>
                      <span v-else>
                        <b-button
                          class="mr-1 small"
                          size="xs"
                          variant="outline-info"
                          :pressed="props.option.favorite"
                          @click.stop="favTxToggle(props.option)"
                        >
                          <i class="far fa-star"></i>
                        </b-button>
                        {{ customTxDrugsLabel(props.option) }}
                      </span>
                    </template>
                    <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
                  </multiselect>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      @click="openTreatModal"
                      :size="size"
                      >Tx. list</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <template v-slot:description>
                  <div v-if="$v.treatList.$error" class="error">
                    *กรุณาลงข้อมูลการรักษา
                  </div>
                  <div v-else>ดูรายการทั้งหมดใน "Tx. list"</div>
                </template>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col mb-2">
              <b-input-group append="จำนวน" :size="size">
                <b-form-input
                  :size="size"
                  :min="1"
                  type="number"
                  number
                  :formatter="toNumber"
                  lazy-formatter
                  v-model="quantity"
                  @change="assignPrice"
                ></b-form-input>
              </b-input-group>
            </div>
            <b-form-group class="mb-2 col">
              <b-input-group append="บาท" :size="size">
                <b-form-input
                  ref="treatmentPriceInput"
                  :class="{
                    'is-invalid': $v.price.$error,
                  }"
                  type="number"
                  placeholder="฿"
                  v-model="$v.price.$model"
                  number
                  lazy-formatter
                  :formatter="toNumber"
                ></b-form-input>
              </b-input-group>
              <template v-slot:description>
                <div :class="{ error: $v.price.$error }">
                  *กรุณาลงราคา ถ้าไม่มีใส่ "0"
                </div>
              </template>
            </b-form-group>
          </div>
        </fieldset>
        <b-form-row
          class="mb-2"
          :class="size == 'sm' ? 'limit-height-col-73' : 'limit-height-col-110'"
        >
          <div class="favorite-text-area-container col pr-1">
            <b-form-textarea
              ref="txNoteInput"
              lazy
              :class="{
                'is-invalid': $v.note.$error,
              }"
              v-model="$v.note.$model"
              :placeholder="treatmentDetailPlaceholder"
              :rows="size === 'sm' ? 3 : 4"
              :size="size"
              no-resize
            ></b-form-textarea>
            <FavNoteButton
              storageName="favTxNote"
              @listClicked="handleSelectedFavNote"
            />
          </div>
        </b-form-row>
        <p v-if="$v.note.$error" class="m-0 p-0 description text-danger">
          กรุณาใส่ Cheif Complaint ถ้าไม่มี ใส่ "-"
        </p>
        <b-row class="mb-2">
          <b-col>
            <div v-if="mode === 'edit'"></div>
            <span v-if="indexEdit == -1">
              <div v-if="mode === 'edit'">
                <div class="text-danger">กรุณาเลือก "รายการรักษาวันนี้"</div>
                <div class="text-warning mb-2">
                  หน้าต่างนี้สำหรับการแก้ไข โน๊ตการรักษาเพิ่มเติม
                  และบันทึกด้วยการเขียนเท่านั้น<br />
                  (แนะนำให้ใช้ กรณีที่เขียนประวัติไม่ทัน
                  และต้องการเขียนเพิ่มเติมภายหลัง)
                </div>
              </div>

              <b-button
                type="submit"
                variant="primary"
                class="float-right"
                :disabled="mode === 'edit'"
              >
                เพิ่มรายการรักษา
                <span v-if="writingMode">
                  <span class="d-inline d-md-none d-lg-inline">
                    <i class="fas fa-angle-double-down"></i>
                  </span>
                  <span class="d-none d-md-inline d-lg-none">
                    <i class="fas fa-angle-double-right"></i>
                  </span>
                </span>
                <span v-else>
                  <span class="d-inline d-lg-none">
                    <i class="fas fa-angle-double-down"></i>
                  </span>
                  <span class="d-none d-lg-inline">
                    <i class="fas fa-angle-double-right"></i>
                  </span>
                </span>
              </b-button>
              <!-- <b-button
                class="btn btn-danger float-right mr-2"
                @click="resetAll"
                :size="size"
              >
                รีเซ็ต
              </b-button>-->
            </span>
            <span v-else>
              <b-button
                type="submit"
                class="btn btn-warning float-right"
                :size="size"
                >แก้ไขรายการรักษา</b-button
              >
              <b-button
                class="btn btn-danger float-right mr-2"
                @click="resetAll"
                :size="size"
                >ยกเลิกแก้ไข</b-button
              >
            </span>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <TreatDiagListModal
      ref="treatDiagListModal"
      @selectedDiagItem="tableSelectedDiagList = $event"
    ></TreatDiagListModal>
    <TreatListModal
      ref="treatListModal"
      @selectedTreatment="tableSelectedTreatList = $event"
    ></TreatListModal>

    <!-- Popover -->
    <b-popover id="toothNumberPop" target="toothNumberInput" triggers="focus">
      <div style="width: 200px">
        <TeethDiagram v-model="toothList" @close="hideTeethDiagramPopover" />
      </div>
      <div>
        <b-row class="mt-1">
          <b-col class="pr-1">
            <b-button
              block
              variant="outline-info"
              class="p-0"
              size="sm"
              @click="clearToothList"
              >เคลียร์</b-button
            >
          </b-col>
          <b-col class="pl-1">
            <b-button
              block
              variant="outline-primary"
              class="p-0"
              size="sm"
              @click="hideTeethDiagramPopover"
              >ตกลง</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-popover>

    <b-popover
      id="toothSurfacesPop"
      target="toothSurfacesInput"
      triggers="focus"
      placement="bottom"
    >
      <div style="width: 150px">
        <ToothSurfaces
          :surfaceList="toothSurfaceList"
          @getToothSurfacesPopover="getToothSurfacesPopover"
        />
      </div>
      <div>
        <b-row class="mt-1">
          <b-col class="pr-1">
            <b-button
              block
              variant="outline-info"
              class="p-0"
              size="sm"
              @click="toothSurfaceList = []"
              >เคลียร์</b-button
            >
          </b-col>
          <b-col class="pl-1">
            <b-button
              block
              variant="outline-primary"
              class="p-0"
              size="sm"
              @click="hideToothSurfacesPopover"
              >ตกลง</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-popover>
    <!-- /Popover -->

    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import { eventBus } from "@/main";

import TreatDiagListModal from "@/components/modal/TreatDiagList";
import TreatListModal from "@/components/modal/TreatList";
import TeethDiagram from "@/components/popover/TeethDiagram";
import ToothSurfaces from "@/components/popover/ToothSurfaces.vue";
import FavNoteButton from "@/components/button/FavNote";
import TreatmentPlanTable from "@/components/table/TreatmentPlan";

import numeral from "numeral";

import Loading from "@/components/Loading";

import "@/theme/modal/PaymentHistory.scss";

export default {
  name: "TreatmentInputs",
  components: {
    TreatDiagListModal,
    TreatListModal,
    TeethDiagram,
    ToothSurfaces,
    Loading,
    FavNoteButton,
    TreatmentPlanTable,
  },
  props: ["appointment", "indexEdit", "size", "writingMode", "mode"],
  data() {
    return {
      actionUid: null,

      toothList: null,
      toothItems: null,

      toothId: null,
      toothHover: null,

      toothSurfaceList: [],
      surfaceItems: [],

      tableSelectedDiagList: [],
      treatDiagList: null,
      diagItems: null,

      tableSelectedTreatList: [],
      treatList: null,
      treatItems: null,
      name: "",
      nameEn: "",
      quantity: null,
      price: 0,
      note: "",

      id: null,
      treatId: "",
      branchId: "",
      typeId: 1,
      df: null,
      dfType: null,

      extendedProps: this.appointment?.extendedProps,

      treatmentDetailPlaceholder:
        "โน๊ตการรักษาเพิ่มเติม เช่น Cheif complaint...",
      isLoading: false,
    };
  },
  validations: {
    toothList: {
      required: requiredIf(function () {
        return this.toothSurfaceList.length > 0 || this.isNhso;
      }),
    },
    treatDiagList: {
      required: requiredIf(function () {
        return this.isNhso;
      }),
    },
    treatList: { required },
    note: {
      required: requiredIf(function () {
        return this.isNhso;
      }),
    },
    price: { required },
    actionUid: { required },
  },
  created() {
    this.init();
  },
  mounted() {
    eventBus.$on("setDefaultValuePaymentHistroy", this.setDefaultTreatmentItem);
    // this.extendedProps = Object.assign(this.appointment.extendedProps);
    // this.patient = Object.assign(this.extendedProps.patient);
    // this.appointmentId = this.appointment.id;
    // this.actionUid = this.extendedProps.doctor;
  },

  watch: {
    appointment: {
      immediate: true,
      handler() {
        this.actionUid = this.appointment?.extendedProps?.doctor;
      },
    },
    toothList(val) {
      if (val?.length > 0) {
        this.toothItems = val.map(i => i.name);
        this.quantity = val.length;
        this.assignPrice();
      } else {
        this.toothItems = null;
      }
    },

    toothSurfaceList() {
      let selectedOptions = [];
      this.toothSurfaceList.forEach(val => {
        selectedOptions.push(val.id);
      });
      this.surfaceItems = selectedOptions;
    },

    tableSelectedDiagList() {
      this.treatDiagList = this.tableSelectedDiagList;
    },
    treatDiagList() {
      if (this.treatDiagList) {
        this.diagItems = this.treatDiagList.id;
      }
    },

    tableSelectedTreatList() {
      this.treatList = this.tableSelectedTreatList;
    },

    treatList(data) {
      if (data) {
        this.treatItems = data.id;
        this.name = data.nameTh;
        this.nameEn = data.nameEn;
        this.df = this.actionUid == 5 ? 0 : data.df;
        this.dfType = data.dfType;
        this.assignPrice();
        //กรณี edit ต้องใส่ราคาจาก item ไม่ใช่จาก default
      } else {
        this.treatItems = null;
        this.name = "";
        this.nameEn = "";
        this.price = 0;
        this.df = null;
        this.dfType = null;
      }
    },
    indexEdit(val) {
      if (this.indexEdit == -1) this.setDefaultTreatmentItem();
    },
    name(val) {
      eventBus.$emit("treatmentName", val);
    },
  },
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      getTreatDiagList: "moduleAppointment/getTreatDiagList",
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",
      getGroupedTreatDiagList: "moduleAppointment/getGroupedTreatDiagList", //ย้าย grouping เข้าไปใน appointment module
      getGroupedTreatDiagListNhso:
        "moduleAppointment/getGroupedTreatDiagListNhso", //ย้าย grouping เข้าไปใน appointment module
      getTreatDiagCateList: "moduleAppointment/getTreatDiagCateList",
      getTreatDiagCateBy: "moduleAppointment/getTreatDiagCateBy",

      getTreatList: "moduleAppointment/getTreatList",
      getTreatBy: "moduleAppointment/getTreatBy",
      getGroupedTreatList: "moduleAppointment/getGroupedTreatList", //ย้าย grouping เข้าไปใน appointment module
      getGroupedTreatListNhso: "moduleAppointment/getGroupedTreatListNhso", //ย้าย grouping เข้าไปใน appointment module
      getTreatCateList: "moduleAppointment/getTreatCateList",
      getTreatCateBy: "moduleAppointment/getTreatCateBy",

      getToothList: "moduleMaster/getToothList",
      getToothBy: "moduleMaster/getToothBy",
      getToothSurfaceList: "moduleMaster/getToothSurfaceList",
      getToothSurfaceBy: "moduleMaster/getToothSurfaceBy",

      getDoctorList: "moduleUser/getDoctorList",
      getDoctorListBy: "moduleUser/getDoctorListBy",

      authorization: "moduleUser/getAuthorization",
      isDoctor: "moduleUser/isDoctor",

      // viewAvatar: "moduleContent/viewAvatar",
    }),
    isNhso() {
      return this.appointment?.extendedProps?.nhsoVisitNo;
    },
    isDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
    enableSurfaceInput() {
      if (this.surfaceItems?.length > 0) return true;
      return this.toothList && this.toothList.filter(i => i.id < 90).length;
    },
    diagnosisList() {
      return this.isNhso
        ? this.getGroupedTreatDiagListNhso
        : this.getGroupedTreatDiagList;
    },
    treatmentList() {
      return this.isNhso
        ? this.getGroupedTreatListNhso
        : this.getGroupedTreatList;
    },
  },
  methods: {
    ...mapActions({}),
    init() {},
    openTreatDiagModal() {
      this.$refs.treatDiagListModal.show(this.treatDiagList);
    },
    openTreatModal() {
      this.$refs.treatListModal.show(this.treatList);
    },

    //* Treatment methods
    async addTreatment() {
      await this.$nextTick();
      this.$v.$touch();
      if (this.$v.$anyError) return;
      let treatmentObj = {
        id: this.id,
        treatId: "",
        branchId: "",
        typeId: 1,
        diagItems: this.diagItems,
        treatItems: this.treatItems,
        toothItems: this.toothItems?.sort(),
        surfaceItems: this.surfaceItems,
        itemId: "",
        quantity: this.quantity,
        price: Number(this.price),
        unitPrice: Number((this.price / (this.quantity || 1)).toFixed(0)),
        note: this.note,
        name: this.name,
        nameEn: this.nameEn,
        actionUid: this.actionUid,
        df: this.df,
        dfType: this.dfType,
      };
      this.$emit("addItem", treatmentObj);
      this.resetAll();
    },
    setDefaultTreatmentItem() {
      this.quantity = null;
      this.price = 0;
      this.note = "";

      this.toothList = null;
      this.toothItems = null;

      this.toothId = null;
      this.toothHover = null;

      this.toothSurfaceList = [];
      this.surfaceItems = [];

      this.tableSelectedDiagList = [];
      this.treatDiagList = null;
      this.diagItems = null;

      this.tableSelectedTreatList = [];
      this.treatList = null;
      this.treatItems = null;
      this.name = "";

      //tretmentItem

      this.id = null;
      this.treatId = "";
      this.branchId = "";
      this.typeId = 1;
      this.itemId = "";
      this.df = null;
      this.dfType = null;

      this.vReset();
    },

    assignTreatmentInput(item) {
      if (item.actionUid) this.actionUid = item.actionUid;
      this.id = item.id;
      this.df = item.df;
      this.dfType = item.dfType;
      this.diagItems = item.diagItems;
      this.itemId = item.itemId;
      this.name = item.name;
      this.note = item.note;
      this.surfaceItems = item.surfaceItems;
      this.toothItems = item.toothItems;
      this.treatItems = item.treatItems;

      //toothList
      if (item.toothItems) {
        this.toothList = [];
        this.toothItems.forEach(i => {
          let toothObj = this.getToothBy(i, "name");
          toothObj = toothObj.id ? toothObj : this.getToothBy(i, "id");
          this.toothList.push(toothObj);
        });
      }
      //toothSurfaceList
      if (item.surfaceItems?.length > 0) {
        this.toothSurfaceList = [];
        item.surfaceItems.forEach(res => {
          this.toothSurfaceList.push(this.getToothSurfaceBy(res, "id"));
        });
      } else {
        this.toothSurfaceList = [];
      }

      //treatDiagList
      if (item.diagItems) {
        this.treatDiagList = Object.assign(
          {},
          this.getTreatDiagBy(item.diagItems, "id")
        );
      }
      //treatList
      if (item.treatItems) {
        this.treatList = Object.assign(
          {},
          this.getTreatBy(item.treatItems, "id")
        );
        this.$nextTick(() => {
          this.price = item.price;
        });
      }
    },
    resetIndexEdit() {
      this.$emit("update:indexEdit", -1);
    },
    resetAll() {
      this.resetIndexEdit();
      this.setDefaultTreatmentItem();
    },

    // Utils
    customDxLabel(libs) {
      return `${libs.code ? "[" + libs.code + "]-" : ""}${libs.nameEn}`;
    },
    customTxDrugsLabel(option) {
      if (option.nameTh == option.nameEn) {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}`;
      } else {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}${
          option.nameEn ? " / " + option.nameEn : ""
        }`;
      }
    },
    customToothLabel(libs) {
      if (libs.label) {
        return `${libs.label}`;
      } else {
        return `${libs.name}`;
      }
    },

    hideTeethDiagramPopover() {
      this.$root.$emit("bv::hide::popover", "toothNumberPop");
      if (this.toothItems.filter(i => i < 90).length > 0) {
        this.$refs.toothSurfacesInput.$el.focus();
      }
    },
    clearToothList() {
      this.toothList = [];
    },
    getToothSurfacesPopover(toothSurfaces) {
      this.toothSurfaceList = [];
      if (toothSurfaces.length > 0) {
        toothSurfaces.forEach(res => {
          this.toothSurfaceList.push(this.getToothSurfaceBy(res, "id"));
        });
      }
    },
    hideToothSurfacesPopover() {
      this.$root.$emit("bv::hide::popover", "toothSurfacesPop");
    },
    vReset() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      });
    },
    formatNumber(val) {
      return numeral(val).format("0,0");
    },
    focusPriceInput() {
      this.$refs.treatmentPriceInput.focus();
      if (this.writingMode) return;
      this.$nextTick(() => {
        this.$refs.treatmentPriceInput.select();
      });
    },

    favDxToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatDiagFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatDiagFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    favTxToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    toNumber(e) {
      return parseFloat(e) || 0;
    },
    handleSelectedFavNote(e) {
      if (!this.note.trim()) {
        this.note = e;
      } else {
        this.note += "\n" + e;
      }
      this.$refs.txNoteInput.focus();
    },
    assignPrice() {
      if (this.quantity > 0 && this.treatList?.price > 0) {
        this.price = this.quantity * this.treatList?.price;
        return;
      }
      this.price = this.treatList?.price || 0;
    },
    handleTreatmentPlanItemClicked(e) {
      const { toothNo, toothSurfaces, diagnosis, treatment, price, detail } = e;
      const item = {
        diagItems: diagnosis,
        note: detail,
        surfaceItems: toothSurfaces || [],
        toothItems: [toothNo],
        treatItems: treatment,
        price,
      };
      this.assignTreatmentInput(item);
    },
  },
  handleEnterKey(event) {
    // Prevent default only if the target is an input
    // This allows textarea to still use enter for new lines
    if (event.target.tagName.toLowerCase() === "input") {
      event.preventDefault();
      this.addTreatment();
    }
  },
};
</script>

<style>
.small-text > .multiselect__content-wrapper {
  font-size: 0.8rem;
}
</style>